<!--eslint-disable-->
<template>
  <div class="roles-comp">
    <b-card class="" title="Manage Roles">
      <b-card-text>This page shows a list of all created roles.</b-card-text>
    </b-card>
    <b-card class="" no-body>
      <div class="row p-2"  v-if="$can('Create', 'Role')">
        <div class="col-12">
          <b-button @click="$bvModal.show('modal-add-role')" variant="primary">Add role</b-button>
        </div>
      </div>
      <b-table
        class="data-table"
        hover
        empty-text="No Networks available"
        :items="roles"
        :fields="headers">
        <template #cell(name)="data">
          <router-link :to="{ name: 'role-view', params: { id: data.item.id.toString() } }" class="font-weight-bold d-block text-nowrap text-underline">
            {{data.item.name}}
          </router-link>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex justify-content-end">
            <b-dropdown class="m-0" variant="link" right toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item-button class="full-nested-btn" v-if="$can('Delete', 'Role')"
                                      @click="showRoleModal(data.item, 'modal-delete-role')">
                Delete
              </b-dropdown-item-button>
            </b-dropdown>
          </div>
        </template>
      </b-table>
    </b-card>

    <b-modal id="modal-add-role" title="Add new role" hide-footer>
      <add-role @refresh="refreshRoles()" @close="closeModals()"></add-role>
    </b-modal>

    <b-modal id="modal-delete-role" title="Delete role" hide-footer>
      <delete-modal
        v-if="role"
        @close="closeModals()" @delete="deleteRole(role)"
        note="The role will be removed from any users attached"
        :subtitle="role.name"
        title="Are you sure you wish to delete role"></delete-modal>
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
  import { mapState } from 'vuex'
  import RolesService from '../../services/RolesService';
  import AddRole from './sections/AddRole.vue';
  import DeleteModal from '../../components/modals/DeleteModal.vue';

  export default {
    name: 'Roles',
    components: {
      AddRole,
      DeleteModal,
    },
    data() {
      return {
        selectedRole: null,
        roles: [],
        headers: [
          {
            key: 'name',
            label: 'Name',
          },
          {
            key: 'actions',
            label: '',
          },
        ],
      };
    },
    computed: {
      ...mapState({
        role: state => state.role.role,
      }),
    },
    mounted() {
      this.getRoles();
    },
    methods: {
      refreshRoles() {
        this.closeModals();
        this.getRoles();
        this.setSelectedRole(null);
      },
      closeModals() {
        this.$bvModal.hide('modal-add-role');
        this.$bvModal.hide('modal-delete-role');
      },
      getRoles() {
        RolesService.getRoles().then(res => {
          this.roles = res.data;
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not get roles, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
      async getRoleDetails(roleId) {
        await this.$store.dispatch('role/getRole', roleId);
      },
      deleteRole(role) {
        RolesService.deleteRole(role.id).then(() => {
          this.$toast.success(`Deleted role ${role.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });

          this.refreshRoles();
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not delete role, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
      setSelectedRole(role) {
        this.selectedRole = role;
      },
      async showRoleModal(role, modalName) {
        await this.getRoleDetails(role.id);
        this.$bvModal.show(modalName);
      },
    },
  }
</script>

<style>

</style>
